import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <h1>Thorn Solutions</h1>
        <div className='content-body'>
          <p className='content-body-text'>
            My website is currently being updated and should be up and running in the near future.
          </p>
          <h3 className='content-body-h3'>Please feel free to contact me through social media:</h3>
          <div className='contact'>
            <div className='social-item'><a href="https://www.facebook.com/davidjamesthorn">Facebook</a></div>
            <div className='social-item'><a href="https://www.youtube.com/thornwebdesign">Youtube</a></div>
            <div className='social-item'><a href="https://www.xing.com/profile/David_Thorn3">Xing</a></div>
            <div className='social-item'><a href="mailto: David Thorn<david.thorn221278@gmail.com?subject=Hey there!">Email</a></div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;
